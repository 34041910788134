import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const BASE_URL = process.env.REACT_APP_API_URL;
const API_URL = `${BASE_URL}/api`;
const CSRF_URL = `${BASE_URL}/sanctum/csrf-cookie`;

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  withXSRFToken: true,
});
console.log(process.REACT_APP_API_URL);

const axiosNoPrefixInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  withXSRFToken: true,
});

let csrfToken = null;

const fetchCsrfToken = async () => {
  try {
    const response = await axios.get(CSRF_URL, { withCredentials: true });
    const csrfToken = response.headers['x-xsrf-token'];
    axiosInstance.defaults.headers.common['X-XSRF-TOKEN'] = csrfToken;
    axiosNoPrefixInstance.defaults.headers.common['X-XSRF-TOKEN'] = csrfToken;
    } catch (error) {
      console.error('Failed to fetch CSRF token:', error);
    }
  };

fetchCsrfToken();

const getAuthHeaders = () => {
  const token = localStorage.getItem('token');
  return token ? { Authorization: `Bearer ${token}` } : {};
};

const addAuthInterceptor = (instance) => {
  instance.interceptors.request.use(
    async (config) => {

      if (!csrfToken) {
        await fetchCsrfToken();
      }
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (response) => {
      if (response.data && response.data.redirect) {
        window.location.href = response.data.redirect;
      }
      return response;
    },
    async (error) => {
      if (error.response && error.response.status === 419) {
        // Token expired or invalid, refresh CSRF token and retry the request
        await fetchCsrfToken();
        error.config.headers['X-XSRF-TOKEN'] = csrfToken;
        return axiosInstance.request(error.config);
      } else if (error.response.status === 403) {
        console.error('Access denied. You may need to verify your email.');
      } else if (error.response.status === 401) {
        console.error('Unauthorized. Please log in again.');
      } else {
        console.error('Network error or no response from the server.');
      }
      return Promise.reject(error);
    }
  );
};


addAuthInterceptor(axiosInstance);
addAuthInterceptor(axiosNoPrefixInstance);

export { axiosInstance, axiosNoPrefixInstance };

export const registerParent = async (name, email, password, passwordConfirmation, firstname, lastname) => {
  try {
    await fetchCsrfToken();
    const response = await axiosNoPrefixInstance.post('/register/parent', {
      name,
      email,
      password,
      password_confirmation: passwordConfirmation,
      firstname,
      lastname,
    });
    return response.data;
  } catch (error) {
    console.error('Failed to register parent:', error);
    throw error;
  }
};

export const createClass = async (class_name, user_id) => {
  try {
    //await fetchCsrfToken();
    const response = await axiosInstance.post('/class/create', { class_name, user_id });
    return response.data;
  } catch (error) {
    console.error('Failed to create class:', error);
    throw error;
  }
};

export const login = async (name, password) => {
  try {
    //await fetchCsrfToken();
    const response = await axiosNoPrefixInstance.post('/login', { name, password });
    if (response.data.access_token) {
      console.log(response.data);
      localStorage.setItem('token', response.data.access_token);
    }
    return response.data;
  } catch (error) {
    console.error('Failed to login:', error);
    throw error;
  }
};

export const logoutUser = async (token) => {
  //await fetchCsrfToken();
  const response = await axiosNoPrefixInstance.post('/logout', null, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};


export const changePassword = async (currentPassword, newPassword, confirmPassword) => {
  try {
    const response = await axiosNoPrefixInstance.post('/change-password', {
      current_password: currentPassword,
      password: newPassword,
      password_confirmation: confirmPassword,
    });
    return response.data;
  } catch (error) {
    console.error('Failed to change password:', error);
    throw error;
  }
};


export const forgotPassword = async (email) => {
  try {
    //await fetchCsrfToken();
    const response = await axiosNoPrefixInstance.post('/forgot/password', { email });
    
    return response.data;
  } catch (error) {
    console.log(error);
    console.error('Failed to send password reset request:', error);
    throw error;
  }
};

  export const fetchResetToken = async (token, email) => {
  try {
    //await fetchCsrfToken();
    const response = await axiosNoPrefixInstance.get(`/reset/password/${token}`, {
      params: { email },
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch reset token:', error);
    throw error;
  }
};

export const resetPassword = async (email, password, confirmPassword, token) => {
  try {
    //await fetchCsrfToken();
    //change password
    const response = await axiosNoPrefixInstance.post('/reset/password', {
      email,
      password,
      password_confirmation: confirmPassword,
      token,
    });
    return response.data;
  } catch (error) {
    console.error('Failed to reset password:', error);
    throw error;
  }
};

export const getTeachers = async (teacherId) => {
  try {
    //await fetchCsrfToken();
    const response = await axiosInstance.get(`/teacher/${teacherId}`, { headers: getAuthHeaders() });
    console.log("teacher id " + response);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch teachers:', error);
    throw error;
  }
};

export const getParents = async (parentId) => {
  try {
    //await fetchCsrfToken();
    const response = await axiosInstance.get(`/parent/${parentId}`, { headers: getAuthHeaders() });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch parents:', error);
    throw error;
  }
};

export const getChildren = async (parentId) => {
  try {
    //await fetchCsrfToken();
    const response = await axiosInstance.get(`/parent/${parentId}/children`, { headers: getAuthHeaders() });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch children:', error);
    throw error;
  }
};

export const searchParents = async (searchTerm) => {
  try {
    //await fetchCsrfToken();
    const response = await axiosInstance.get('/parents/search', {
      params: { search: searchTerm },
    });
    return response.data;
  } catch (error) {
    console.error('Failed to search parents:', error);
    throw error;
  }
};

export const addChild = async (parentId, childData) => {
  try {
    //await fetchCsrfToken();
    const response = await axiosInstance.post(`/parent/${parentId}/add-child`, childData, {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error('Failed to add child:', error);
    throw error;
  }
};

export const registerTeacher = async (name, email, password, confirmPassword, firstname, lastname, school_id) => {
  try {
    //await fetchCsrfToken();
    const response = await axiosNoPrefixInstance.post('/register/teacher', {
      name,
      email,
      password,
      password_confirmation: confirmPassword,
      firstname,
      lastname,
      school_id,
    });
    return response.data;
  } catch (error) {
    console.error('Failed to register teacher:', error);
    throw error;
  }
};

export const fetchSchools = async () => {
  try {
   // await fetchCsrfToken();
    const response = await axiosInstance.get('/schools');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch schools:', error);
    throw error;
  }
};

export const fetchClasses = async () => {
  try {
    //await fetchCsrfToken();
    const response = await axiosInstance.get('/classes');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch classes:', error);
    throw error;
  }
};

export const deleteClass = async (classId) => {
  try {
    const response = await axiosInstance.delete(`/classes/${classId}`, {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error('Failed to delete class');
    throw error;
  }
}



export const addBookmark = async (bookmarkData) => {
  try {
    //await fetchCsrfToken();
    const response = await axiosInstance.post('/bookmarks', bookmarkData, {
      headers: getAuthHeaders(),
    });
    return response;
  } catch (error) {
    console.error('Failed to add bookmark:', error);
    throw error;
  }
};

export const removeBookmark = async (bookmarkId, teacherId, classId) => {
  try {
    //await fetchCsrfToken();
    const response = await axiosInstance.delete(`/bookmarks/${bookmarkId}`, {
      headers: getAuthHeaders(),
      data: {
        teacher_id: teacherId,
        class_id: classId,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Failed to remove bookmark:', error);
    throw error;
  }
};

export const fetchBookmarks = async () => {
  try {
    //await fetchCsrfToken();
    const response = await axiosInstance.get('/bookmarks/get', {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch bookmarks:', error);
    throw error;
  }
};

export const fetchBookmarksByClass = async (classId) => {
  try {
    //await fetchCsrfToken();
    const response = await axiosInstance.get(`/bookmarks/${classId}`, {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch bookmarks:', error);
    throw error;
  }
};

export const getProfilePic = async () => {
  try {
    //await fetchCsrfToken();
    const response = await axiosInstance.get('/user/get/profile-pic', {
      headers: getAuthHeaders(),
    });
    return response.data.profile_pic;
  } catch (error) {
    console.error('Failed to fetch profile pic:', error);
    throw error;
  }
};

export const getAvatar = async () => {
  try {
    //await fetchCsrfToken();
    const response = await axiosInstance.get('/avatars', {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch avatars:', error);
    throw error;
  }
};

export const setProfilePic = async (profilePic) => {
  try {
    //await fetchCsrfToken();
    const response = await axiosInstance.post('/user/set/profile-pic', { profile_pic: profilePic }, {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error('Failed to set profile pic:', error);
    throw error;
  }
};

export const getUser = async (token) => {
  //await fetchCsrfToken();
  return axiosInstance.get('/user', {
    headers: { Authorization: `Bearer ${token}` },
  });
};


export const getTeacherId = async (userId) => {
  //await fetchCsrfToken();
  const response = await axiosInstance.get(`/teacher/${userId}/teacher-id`, {
    headers: getAuthHeaders(),
  });
  return response.data.teacher_id;
};

export const getClasses = async (teacherId) => {
  //await fetchCsrfToken();
  const response = await axiosInstance.get(`/teacher/${teacherId}/classes`, {
    headers: getAuthHeaders(),
  });
  return response.data;
};

export const getBookmarksByClass = async (classId, token) => {
  //await fetchCsrfToken();
  return axiosInstance.get(`/bookmarks/${classId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getTeacherData = async (teacherId) => {
  //await fetchCsrfToken();
  const response = await axiosInstance.get(`/teacher/${teacherId}`, {
    headers: getAuthHeaders(),
  });
  return response.data;
};

export const getTeacherClasses = async (teacherId) => {
  //await fetchCsrfToken();
  const response = await axiosInstance.get(`/teacher/${teacherId}/classes`, {
    headers: getAuthHeaders(),
  });
  return response.data;
};

export const getChildData = async (childId) => {
  //await fetchCsrfToken();
  const response = await axiosInstance.get(`/child/${childId}`, {
    headers: getAuthHeaders(),
  });
  return response.data;
};

export const setChildAvatar = async (childId, formData) => {
 // await fetchCsrfToken();
  const response = await axiosInstance.post(`/user/${childId}/avatar`, formData, {
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'multipart/form-data'
    },
  });
  return response.data;
};

export default axiosInstance;
