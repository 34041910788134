import React, { useState } from 'react';
import { addChild } from '../../services/axiosInstance';
import '../../css/Forms/registerChild.css';

const AddChildOverlay = ({ user, onClose, onChildAdded }) => {

    const MAX_USERNAME_LENGTH = 20;
    const [childData, setChildData] = useState({
        firstname: '',
        lastname: '',
        name: '', 
        password: '',
    });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        


        setChildData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const response = await addChild(user.id, childData);
            onChildAdded(response.child);
            onClose();
        } catch (error) {
            console.error('Failed to add child', error);
            setError('Failed to add child');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="overlay">
            <div className="register-child-wrapper">
                <button className="close-btn" onClick={onClose}>×</button>
                <h2 className="title">Register Child</h2>
                {error && <p className="error">{error}</p>}
                <form onSubmit={handleSubmit} className="register-child-form">
                    <div className="form-group">
                        <label htmlFor="firstname">First Name</label>
                        <input
                            type="text"
                            id="firstname"
                            name="firstname"
                            value={childData.firstname}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="lastname">Last Name</label>
                        <input
                            type="text"
                            id="lastname"
                            name="lastname"
                            value={childData.lastname}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">Username</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={childData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={childData.password}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button type="submit" className="submit-btn" disabled={loading}>
                        {loading ? 'Registering...' : 'Register'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default AddChildOverlay;
