import React, { useState, useEffect, useContext } from 'react';
import { getParents, getChildren, setProfilePic as saveProfilePic } from '../../services/axiosInstance';
import AvatarSelectionOverlay from './AvatarSelection';
import ChangePasswordOverlay from '../Login/ChangePassword';
import defaultImage from "../../images/UserProfile/Avatar_default.png";
import addGraphic from "../../images/Addgraphic.png";
import KidsNavbar from "../../components/Kids/KidsNavbar.jsx";
import Footer from "../../components/Homepage/Footer.jsx";
import AddChildOverlay from '../Forms/RegisterChild.jsx';
import ChildDetailsOverlay from './ChildDetailsOverlay'; 
import { AuthContext } from '../../contexts/AuthContext';
//import "../../css/footer.css";
import "../../css/Dashboards/ParentDashboard.css";

const ParentDashboard = ({ user }) => {
    const { profilePic, setProfilePic, childAvatars, setChildAvatars, children, setChildren } = useContext(AuthContext);
    const [parent, setParent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isAvatarSelectionOpen, setIsAvatarSelectionOpen] = useState(false);
    const [isAddChildOpen, setIsAddChildOpen] = useState(false);
    const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
    const [selectedChild, setSelectedChild] = useState(null);  // State for selected child

    useEffect(() => {
        const fetchParentData = async () => {
            try {
                const parentData = await getParents(user.id);
                setParent(parentData.parent);
            } catch (error) {
                console.error('Failed to fetch parent data', error);
                setError('Failed to fetch parent data');
            } finally {
                setLoading(false);
            }
        };

        const fetchChildrenData = async () => {
            try {
                const childrenData = await getChildren(user.id);
                setChildren(childrenData.children);

                // Create a map of child IDs to profile pictures
                const avatars = {};
                childrenData.children.forEach(child => {
                    avatars[child.id] = child.profile_pic;
                });
                setChildAvatars(avatars);
                console.log('Child avatars:', avatars); // Log to verify

            } catch (error) {
                console.error('Failed to fetch children', error);
                setError('Failed to fetch children data');
            }
        };

        fetchParentData();
        fetchChildrenData();
    }, [user.id, setChildren, setChildAvatars]);

    const updateProfilePic = async (newProfilePic) => {
        try {
            await saveProfilePic(newProfilePic);
            setProfilePic(newProfilePic);
            closeAvatarSelection();
        } catch (error) {
            console.error('Failed to update profile picture', error);
        }
    };

    const openAvatarSelection = () => {
        setIsAvatarSelectionOpen(true);
    };

    const closeAvatarSelection = () => {
        setIsAvatarSelectionOpen(false);
    };

    const openAddChild = () => {
        setIsAddChildOpen(true);
    };

    const closeAddChild = () => {
        setIsAddChildOpen(false);
    };

    const handleChildAdded = (newChild) => {
        setChildren((prevChildren) => [...prevChildren, newChild]);
    };

    const openChangePassword = () => {
        setIsChangePasswordOpen(true);
    };

    const closeChangePassword = () => {
        setIsChangePasswordOpen(false);
    };

    const openChildDetails = (child) => {
        setSelectedChild(child);
    };

    const closeChildDetails = () => {
        setSelectedChild(null);
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status"></div>
            </div>
        );
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="parent-dashboard-home-container">
            <div className="dasboard">
                <KidsNavbar />
            </div>
            <div className="parent-dashboard-content">
                <div className="parent-content">
                    <h1 className="parent-title">Grown Up Account</h1>
                    <div className="parent-profile-container">
                        <div className="profile-container">
                            <img src={profilePic} alt="Profile" className="profile-image" />
                            <div className="profile-overlay" onClick={openAvatarSelection}>
                                <img src={addGraphic} alt="Change Profile" />
                            </div>
                        </div>
                        <div className="parent-info">
                            <p className="parent-username">Username: {user.name}</p>
                            <p className="parent-email">Email: {user.email}</p>
                            <button onClick={openChangePassword} className="teacher-change-password">Change password</button>
                        </div>
                    </div>
                    <div className="button-container">
                        <button className="parent-add-child-btn" onClick={openAddChild}>Add Child</button>
                    </div>
                </div>
                {isAvatarSelectionOpen && <AvatarSelectionOverlay onClose={closeAvatarSelection} onSelectAvatar={updateProfilePic} />}
                {isAddChildOpen && <AddChildOverlay user={user} onClose={closeAddChild} onChildAdded={handleChildAdded} />}
                {isChangePasswordOpen && <ChangePasswordOverlay onClose={closeChangePassword} />}
                <div className="children-container">
                    <h3>Children</h3>
                    <ul className="children-list">
                        {Array.isArray(children) && children.map((child) => (
                            <li key={child.id} className="child-item">
                                <div className="profile-container" onClick={() => openChildDetails(child)}>
                                    <img src={childAvatars[child.id] || addGraphic} alt="Child Avatar" className="child-avatar" />
                                    <div className="profile-overlay">
                                        <img src={addGraphic} alt="View Details" />
                                    </div>
                                </div>
                                <p className='childName'>{child.firstname}</p>
                            </li>
                        ))}
                        
                        {Array.from({ length: 4 - (children?.length || 0) }).map((_, index) => (
                            <li key={index} className="child-item">
                                <button className="add-child-btn" onClick={openAddChild}>
                                    <img src={addGraphic} alt="Add Child" className="child-avatar" />
                                    <p className='defchildName'> Child</p>
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="footer">
                <Footer />
            </div>
            {selectedChild && <ChildDetailsOverlay child={selectedChild} onClose={closeChildDetails} />}
        </div>
    );
};

export default ParentDashboard;
