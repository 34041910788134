
export const setCache = (key, data) => {
    const timestampedData = {
        data,
        timestamp: new Date().getTime(),
    };
    localStorage.setItem(key, JSON.stringify(timestampedData));
};

export const getCache = (key, maxAge = 600000) => {
    const cachedItem = localStorage.getItem(key);
    if (!cachedItem) return null;

    const { data, timestamp } = JSON.parse(cachedItem);
    if (new Date().getTime() - timestamp > maxAge) {
        localStorage.removeItem(key);
        return null;
    }

    return data;
};

export const clearCache = (key) => {
    localStorage.removeItem(key);
};
