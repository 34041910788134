import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';

const ProtectedRoute = ({ children, userRole }) => {
  const { user, loading } = useContext(AuthContext);

  useEffect(() => {
    if (user) {
      console.log("User context initialized:", user);
    } else {
      console.log("No user found, redirecting to login.");
    }
  }, [user]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/" />;
  }

  console.log("User role: ", user?.role);
  console.log("Allowed roles: ", userRole);

  if (Array.isArray(userRole)) {
    if (!userRole.includes(user?.role)) {
      console.log("User role not authorized, redirecting to home.");
      return <Navigate to="/" />;
    }
  } else {
    if (user?.role !== userRole) {
      console.log("User role does not match, redirecting to home.");
      return <Navigate to="/" />;
    }
  }

  return children;
};

export default ProtectedRoute;



