import React, { useContext, useNavigate } from 'react';
import { AuthContext } from '../../contexts/AuthContext';

import TeacherDashboard from './TeacherDashboard';
import ParentDashboard from './ParentDashboard';
import ChildDashboard from './ChildDashboard';

const Dashboard = () => {
    const { user, loading } = useContext(AuthContext);
    console.log("AuthContext loading state: ", loading);
    console.log("AuthContext user state: ", user);


    if (loading) {
        return <div></div>;
    }

    if (!user) {
        return <div>Unauthorised</div>;
    }

    const currentUser = user;
    console.log("user in dashboard: ", currentUser);

    switch (currentUser.role) {
        case 'teacher':
            return <TeacherDashboard user={currentUser} />;
        case 'parent':
            return <ParentDashboard user={currentUser} />;
        case 'child':
            return <ChildDashboard user={currentUser} />;
        default:
            return <div>Unauthorised</div>;
    }
};

export default Dashboard;
