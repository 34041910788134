import React, { useState } from 'react';
import { changePassword } from '../../services/axiosInstance';
import "../../css/Forms/overlay.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const ChangePasswordOverlay = ({ onClose }) => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmNewPassword) {
            setError('New password and confirmation do not match.');
            return;
        }
        try {
            const response = await changePassword(currentPassword, newPassword, confirmNewPassword);
            setMessage(response.message);
            setError('');
            setTimeout(() => {
                setMessage('');
                onClose();
            }, 2000);
        } catch (error) {
            setError('Failed to change password. Please try again.');
            setMessage('');
        }
    };

    const toggleCurrentPasswordVisibility = () => {
        setShowCurrentPassword(!showCurrentPassword);
    };

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleConfirmNewPasswordVisibility = () => {
        setShowConfirmNewPassword(!showConfirmNewPassword);
    };

    return (
        <div className="overlay">
            <div className="form_wrapper">
                <button className="close-btn" onClick={onClose}>×</button>
                <div className="form_container">
                    <div className="title_container">
                        <h2>Change Password</h2>
                    </div>
                    {message && <p className="success-message">{message}</p>}
                    {error && <p className="error-message">{error}</p>}
                    <div className="row clearfix">
                        <div className="inputs">
                            <form onSubmit={handleSubmit}>
                                <div className="input_field">
                                    <span><i aria-hidden="true" className="fa fa-lock"></i></span>
                                    <input
                                        type={showCurrentPassword ? "text" : "password"}
                                        name="current_password"
                                        value={currentPassword}
                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                        placeholder="Current Password"
                                        required
                                    />
                                    <button type="button" className="toggle-password" onClick={toggleCurrentPasswordVisibility}>
                                        <FontAwesomeIcon icon={showCurrentPassword ? faEyeSlash : faEye} />
                                    </button>
                                </div>
                                <div className="input_field">
                                    <span><i aria-hidden="true" className="fa fa-lock"></i></span>
                                    <input
                                        type={showNewPassword ? "text" : "password"}
                                        name="new_password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        placeholder="New Password"
                                        required
                                    />
                                    <button type="button" className="toggle-password" onClick={toggleNewPasswordVisibility}>
                                        <FontAwesomeIcon icon={showNewPassword ? faEyeSlash : faEye} />
                                    </button>
                                </div>
                                <div className="input_field">
                                    <span><i aria-hidden="true" className="fa fa-lock"></i></span>
                                    <input
                                        type={showConfirmNewPassword ? "text" : "password"}
                                        name="password_confirmation"
                                        value={confirmNewPassword}
                                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                                        placeholder="Confirm New Password"
                                        required
                                    />
                                    <button type="button" className="toggle-password" onClick={toggleConfirmNewPasswordVisibility}>
                                        <FontAwesomeIcon icon={showConfirmNewPassword ? faEyeSlash : faEye} />
                                    </button>
                                </div>
                                <div className="flex-justify-end">
                                    <input className="button" type="submit" value="Change Password" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangePasswordOverlay;
