import React, { useState, useContext } from 'react';
import "../../css/Forms/overlay.css";
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { login, forgotPassword } from '../../services/axiosInstance';

const LoginOverlay = ({ onClose }) => {
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const {setUser, setChildren } = useContext(AuthContext);
    const [showForgotPassword, setShowForgotPassword] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = await login(name, password);
            console.log(data.access_token);
            localStorage.setItem('token', data.access_token);
            setUser(data.user);
            setChildren(data.children);
        } catch (error) {
            console.log(error);
            setError('Login failed. Please try again.');
        }
    };

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        try {
            await forgotPassword(email);
            setError('If the email is registered, a reset link will be sent to it.');
        } catch (error) {
            setError('Failed to send reset link. Please try again.');
        }
    };

    const toggleForgotPassword = () => {
        setShowForgotPassword(!showForgotPassword);
        setError('');
    };

    return (
        <div className="overlay">
            <div className="form_wrapper">
                <button className="close-btn" onClick={onClose}>×</button>
                <div className="form_container">
                    <div className="title_container">
                        <h2>{showForgotPassword ? 'Forgot Password' : 'Login'}</h2>
                    </div>
                    {error && <p className="error-message">{error}</p>}
                    <div className="row clearfix">
                        <div className="inputs">
                            {showForgotPassword ? (
                                <form onSubmit={handleForgotPassword}>
                                    <div className="input_field">
                                        <span><i aria-hidden="true" className="fa fa-envelope"></i></span>
                                        <input
                                            type="email"
                                            name="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder="Email"
                                            required
                                        />
                                    </div>
                                    <div className="flex-justify-end">
                                        <input className="button" type="submit" value="Reset Password" />
                                    </div>
                                    <div className="flex-justify-end">
                                        <button className="link-btn" onClick={toggleForgotPassword}>Back to Login</button>
                                    </div>
                                </form>
                            ) : (
                                <form onSubmit={handleSubmit}>
                                    <div className="input_field">
                                        <span><i aria-hidden="true" className="fa fa-user"></i></span>
                                        <input
                                            type="text"
                                            name="name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            placeholder="Username"
                                            required
                                        />
                                    </div>
                                    <div className="input_field">
                                        <span><i aria-hidden="true" className="fa fa-lock"></i></span>
                                        <input
                                            type="password"
                                            name="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            placeholder="Password"
                                            required
                                        />
                                    </div>
                                    <div className="flex-justify-end">
                                        <input className="button" type="submit" value="Login" />
                                    </div>
                                    <div className="password_forget">
                                        <button className="link-btn" onClick={toggleForgotPassword}>Forget your password? Click here to reset</button>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginOverlay;
