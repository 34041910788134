import React, { useEffect, useState, useContext } from "react";
import axiosInstance from '../../services/axiosInstance';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars, faCaretDown, faCaretUp, faXmark
} from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import "../../css/Kids/kidsnavbar.css";
import RegisterSign from "../Login/RegisterSign";
import defaultImage from "../../images/UserProfile/Avatar_default.png";
import logo from "../../images/checkpoint_logos/Checkpoint®_stacked_72ppi.png";

function KidsNavbar() {
  const { user, logout, loading } = useContext(AuthContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownArrow, setDropdownArrow] = useState(faCaretDown);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); 
  const [mobileIcon, setMobileIcon] = useState(faBars);
  const [showRegisterOrSign, setShowRegisterOrSign] = useState(false);
  const [profilePic, setProfilePicState] = useState(defaultImage);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const navigate = useNavigate();

  const toggleDropdownMenu = () => {
    setShowDropdown(!showDropdown);
  };

  const handleDropdownArrowClick = () => {
    setDropdownArrow(dropdownArrow === faCaretDown ? faCaretUp : faCaretDown);
  };

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
    if (!showMobileMenu) {
      setDropdownArrow(faCaretDown);
      setShowDropdown(false);
    }
    setMobileIcon(showMobileMenu ? faBars : faXmark);
  };

  const handleIconClick = () => {
    setMobileIcon(mobileIcon === faBars ? faXmark : faBars);
  };

  const toggleRegisterOrSign = () => {
    setShowRegisterOrSign(!showRegisterOrSign);
  };

  const toggleProfileDropdown = () => {
    setShowProfileDropdown(!showProfileDropdown);
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(()=>{
    //track the width on resize
    window.addEventListener("resize", handleResize);

    return()=>{
      window.removeEventListener("resize", handleResize);
    }
  }, []);

  useEffect(() => {
    const fetchProfilePic = async () => {
      try {
        const response = await axiosInstance.get('/user/get/profile-pic', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        });
        if (response.data && response.data.profile_pic) {
          setProfilePicState(response.data.profile_pic);
        } else {
          setProfilePicState(defaultImage);
        }
      } catch (error) {
        console.error('Failed to fetch profile picture', error);
        setProfilePicState(defaultImage);
      }
    };

    if (user) {
      fetchProfilePic();
    }
  }, [user]);

  return (
    <div className="kids-navbar-cnt2">
      <div className="home-logo2">
        <a href="/">
          <img src={logo} alt="Checkpoint logo" />
        </a>
      </div>
      {windowWidth <= 768 ? (
        <div>
          <div className="kids-icon-menu" onClick={toggleMobileMenu}>
            <FontAwesomeIcon
              className={`kids-dropdown-icon ${showMobileMenu ? "open" : ""}`}
              icon={mobileIcon}
              onClick={handleIconClick}
            />
          </div>

          {showMobileMenu && (
            <div className="kids-nav-menu">
              
            <div className="kids-nav-item-profile-dropdown-container2">
            {user ? (
              <div className={`profile-dropdown ${showProfileDropdown ? "show" : ""}`} onClick={toggleProfileDropdown}>
                <div className="profile-info">
                  <img src={profilePic} alt="Profile" className="user-icon" />
                  <span className="user-name">{user.name}</span>
                </div>
                {showProfileDropdown && (
                  <div className="profile-dropdown-content-kids2">
                    <a href="/dashboard">Dashboard</a>
                    <a onClick={logout}>Logout</a>
                  </div>
                )}
              </div>
            ) : (
              <a onClick={toggleRegisterOrSign}>
                <button className="kids-nav-btn">Login/Signup</button>
              </a>
            )}
            {showRegisterOrSign && <RegisterSign onClose={toggleRegisterOrSign} />}
              </div>
              <button className="kids-nav-btn">
                <a href="/kidsmain/kidsmainslide">Kids Home</a>
              </button>
              <button className="kids-nav-btn">
                <a href="/kidsmain/magazine">Kids Magazine</a>
              </button>
              <div
                className={`kids-nvb-dpd ${showDropdown ? "show" : ""}`}
                onClick={toggleDropdownMenu}
              >
                <button className="kids-nvb-item" onClick={handleDropdownArrowClick}>
                  Learning
                  <FontAwesomeIcon className="dropdown-arrow" icon={dropdownArrow} />
                </button>
                <div className="kids-dpd-cnt">
                  {showDropdown && (
                    <div>
                      <button>
                        <a href="/kidsmain/homelearning">Home Learning</a>
                      </button>
                      <button>
                        <a href="/kidsmain/teacherlessons">Teacher Lessons</a>
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <button className="kids-nav-btn">
                <a href="/kidsmain/bedtimestories">Bedtime stories</a>
              </button>
              <button className="kids-nav-btn">
                <a href="/kidsmain/create">Create</a>
              </button>
              <button className="kids-nav-btn">
                <a href="/kidsmain/submissions">Submissions</a>
              </button>
              <button className="kids-nav-btn">
                <a href="/kidsmain/friends">Friends</a>
              </button>


            </div>
          )}
        </div>
      ) : (
        <div className="kids-nav-menu">
          <button className="kids-nav-btn">
            <a href="/kidsmain/kidsmainslide">Kids Home</a>
          </button>
          <button className="kids-nav-btn">
            <a href="/kidsmain/magazine">Kids Magazine</a>
          </button>
          <div
            className={`kids-nvb-dpd ${showDropdown ? "show" : ""}`}
            onClick={toggleDropdownMenu}
          >
            <button className="kids-nvb-item" onClick={handleDropdownArrowClick}>
              Learning
              <FontAwesomeIcon className="dropdown-arrow" icon={dropdownArrow} />
            </button>
            <div className="kids-dpd-cnt">
              {showDropdown && (
                <div>
                  <button>
                    <a href="/kidsmain/homelearning">Home Learning</a>
                  </button>
                  <button>
                    <a href="/kidsmain/teacherlessons">Teacher Lessons</a>
                  </button>
                </div>
              )}
            </div>
          </div>

          <button className="kids-nav-btn">
            <a href="/kidsmain/bedtimestories">Bedtime stories</a>
          </button>
          <button className="kids-nav-btn">
            <a href="/kidsmain/create">Create</a>
          </button>
          <button className="kids-nav-btn">
            <a href="/kidsmain/submissions">Submissions</a>
          </button>
          <button className="kids-nav-btn">
            <a href="/kidsmain/friends">Friends</a>
          </button>

        </div>
      )}

      <div className="kids-nav-item-profile-dropdown-container">
            {user ? (
              <div className={`profile-dropdown ${showProfileDropdown ? "show" : ""}`} onClick={toggleProfileDropdown}>
                <div className="profile-info">
                  <img src={profilePic} alt="Profile" className="user-icon" />
                  <span className="user-name">{user.name}</span>
                </div>
                {showProfileDropdown && (
                  <div className="profile-dropdown-content-kids">
                    <a href="/dashboard">Dashboard</a>
                    <a onClick={logout}>Logout</a>
                  </div>
                )}
              </div>
            ) : (
              <a onClick={toggleRegisterOrSign}>
                <button className="kids-nav-btn">Login/Signup</button>
              </a>
            )}
            {showRegisterOrSign && <RegisterSign onClose={toggleRegisterOrSign} />}
          </div>
    </div>
  );
}

export default KidsNavbar;
