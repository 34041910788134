import React, { useState } from 'react';
import { registerParent } from '../../services/axiosInstance';
import CustomPopup from '../../popup/popup';
import "../../css/Forms/overlay.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const RegisterAdultOverlay = ({ onClose, onRegisterSuccess }) => {

    const MAX_USERNAME_LENGTH = 20;
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        firstname: '',
        lastname: ''
    });
    const [error, setError] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [success, setSuccess] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'name' && value.length > MAX_USERNAME_LENGTH) {
            setUsernameError(`Username cannot exceed ${MAX_USERNAME_LENGTH} characters`);
        } else {
            setUsernameError('');
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, email, password, confirmPassword, firstname, lastname } = formData;
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }
        try {
            const response = await registerParent(name, email, password, confirmPassword, firstname, lastname);
            console.log('Registration successful', response);
            localStorage.setItem('token', response.access_token);
            setSuccess(true);
            setShowPopup(true);
        } catch (error) {
            console.error('Registration failed', error);
            setError('Registration failed. Please try again.');
        }
    };

    const handlePopupClose = () => {
        setShowPopup(false);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <div className="overlay">
            <div className="form_wrapper">
                {showPopup && (
                    <CustomPopup onClose={handlePopupClose}>
                        <h2 style={{ color: 'white', textAlign: 'center' }}>Registration successful</h2>
                        <p style={{ color: 'white', textAlign: 'center' }}>A verification email has been sent to your email address</p>
                    </CustomPopup>
                )}
                <button className="close-btn" onClick={onClose}>×</button>
                <div className="form_container">
                    <div className="title_container">
                        <h2>Parent Registration</h2>
                    </div>
                    {error && <p className="error-message">{error}</p>}
                    <div className="row clearfix">
                        <div className="">
                            <form onSubmit={handleSubmit}>
                                <div className="input_field">
                                    <span><i aria-hidden="true" className="fa fa-user"></i></span>
                                    <input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        placeholder="Username"
                                        required
                                    />
                                    {usernameError && <p className="error-message">{usernameError}</p>}
                                </div>
                                <div className="input_field">
                                    <span><i aria-hidden="true" className="fa fa-envelope"></i></span>
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        placeholder="Email"
                                        required
                                    />
                                </div>
                                <div className="input_field">
                                    <span><i aria-hidden="true" className="fa fa-lock"></i></span>
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        placeholder="Password"
                                        required
                                    />
                                    <button type="button" className="toggle-password" onClick={togglePasswordVisibility}>
                                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                    </button>
                                </div>
                                <div className="input_field">
                                    <span><i aria-hidden="true" className="fa fa-lock"></i></span>
                                    <input
                                        type={showConfirmPassword ? "text" : "password"}
                                        name="confirmPassword"
                                        value={formData.confirmPassword}
                                        onChange={handleChange}
                                        placeholder="Confirm Password"
                                        required
                                    />
                                    <button type="button" className="toggle-password" onClick={toggleConfirmPasswordVisibility}>
                                        <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                                    </button>
                                </div>
                                <div className="row clearfix">
                                    <div className="col_half">
                                        <div className="input_field">
                                            <span><i aria-hidden="true" className="fa fa-user"></i></span>
                                            <input
                                                type="text"
                                                name="firstname"
                                                value={formData.firstname}
                                                onChange={handleChange}
                                                placeholder="First Name"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col_half">
                                        <div className="input_field">
                                            <span><i aria-hidden="true" className="fa fa-user"></i></span>
                                            <input
                                                type="text"
                                                name="lastname"
                                                value={formData.lastname}
                                                onChange={handleChange}
                                                placeholder="Last Name"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-justify-end">
                                    <input className="button" type="submit" value="Register" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterAdultOverlay;
