import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from '../../services/axiosInstance';
import "../../css/Forms/overlay.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const ResetPassword = ({ token, email, onClose }) => {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await resetPassword(email, password, passwordConfirmation, token);
      setMessage(response.message);
      setError('');
      setTimeout(() => {
        onClose();
        navigate('/');
      }, 2000);
    } catch (error) {
      setError('Failed to reset password. Please try again.');
      setMessage('');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordConfirmationVisibility = () => {
    setShowPasswordConfirmation(!showPasswordConfirmation);
  };

  return (
    <div className="overlay">
      <div className="form_wrapper">
        <button className="close-btn" onClick={onClose}>×</button>
        <div className="form_container">
          <div className="title_container">
            <h2>Reset Password</h2>
          </div>
          {message && <p className="success-message">{message}</p>}
          {error && <p className="error-message">{error}</p>}
          <div className="row clearfix">
            <div className="inputs">
              <form onSubmit={handleSubmit}>
                <div className="input_field">
                  <span><i aria-hidden="true" className="fa fa-lock"></i></span>
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="New Password"
                    required
                  />
                  <button type="button" className="toggle-password" onClick={togglePasswordVisibility}>
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </button>
                </div>
                <div className="input_field">
                  <span><i aria-hidden="true" className="fa fa-lock"></i></span>
                  <input
                    type={showPasswordConfirmation ? "text" : "password"}
                    name="password_confirmation"
                    value={passwordConfirmation}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                    placeholder="Confirm Password"
                    required
                  />
                  <button type="button" className="toggle-password" onClick={togglePasswordConfirmationVisibility}>
                    <FontAwesomeIcon icon={showPasswordConfirmation ? faEyeSlash : faEye} />
                  </button>
                </div>
                <div className="flex-justify-end">
                  <input className="button" type="submit" value="Reset Password" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
