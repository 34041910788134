import React, { useState } from 'react';
import "../../css/Forms/createClass.css";
import { createClass } from '../../services/axiosInstance';

const CreateClassOverlay = ({ user, onClose, onClassAdded }) => {
    const [className, setClassName] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await createClass(className, user.id);
            onClassAdded(response);
            setSuccess('Class created successfully!');
            setClassName('');
            onClose();
        } catch (error) {
            setError('Class creation failed. Please try again.');
        }
    };

    return (
        <div className="create-class-overlay">
            <div className="create-class-form-wrapper">
                <button className="create-class-close-btn" onClick={onClose}>×</button>
                <div className="create-class-form-container">
                    <div className="create-class-title-container">
                        <h2>Class Registration</h2>
                    </div>
                    {error && <p className="create-class-error-message">{error}</p>}
                    {success && <p className="create-class-success-message">{success}</p>}
                    <div className="create-class-row create-class-clearfix">
                        <div className="">
                            <form onSubmit={handleSubmit}>
                                <div className="create-class-input-field">
                                    <input
                                        type="text"
                                        name="class_name"
                                        value={className}
                                        onChange={(e) => setClassName(e.target.value)}
                                        placeholder="Class Name"
                                        required
                                    />
                                </div>
                                <div className="create-class-flex create-class-justify-end">
                                    <input className="create-class-button" type="submit" value="Add Class" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateClassOverlay;
