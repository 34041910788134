import React from 'react';
import './popup.css';

const SuccessPopup = ({ message, onClose }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-black opacity-50 absolute inset-0" onClick={onClose}></div>
            <div className="bg-orange-500 text-white p-4 rounded-lg shadow-lg relative z-10">
                <h2 className="text-xl font-bold">{message}</h2>
                <p className="mt-2">You are being redirected to the login page</p>
                <button
                    className="mt-4 bg-white text-orange-500 px-4 py-2 rounded-lg"
                    onClick={onClose}
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default SuccessPopup;
