import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4';
import { AuthProvider } from './contexts/AuthContext';
import Home from "./pages/Home";
import KidsMain from "./pages/Kids/KidsMain";
import HomeLearning from "./components/Kids/HomeLearning";
import TeacherLessons from "./components/Kids/TeacherLessons";
import Create from "./components/Kids/Create";
import KidsmainMagazine from "./components/Kids/KidsmainMagazine";
import KidsmainBedtimeStories from "./components/Kids/KidsmainBedtimeStories";
import Submissions from "./components/Kids/Submissions";
import KidsmainSlide from "./components/Kids/KidsmainSlide";
import TLessonPreview from "./components/Kids/TLessonPreview";
import HLPreview from "./components/Kids/HLPreview";
import Friends from "./components/Kids/Friends";
import KidsRedirect from "./components/Kids/KidsRedirect";
import LoginPage from './components/Login/LoginOverlay';
import Dashboard from './components/Dashboard/Dashboard';
import ChildDashboard from './components/Dashboard/ChildDashboard';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import RegisterParent from './components/Login/RegisterAdultOverlay';
import RegisterTeacher from './components/Login/RegisterTeacherOverlay';
import TeacherDashboard from './components/Dashboard/TeacherDashboard';
import ParentDashboard from './components/Dashboard/ParentDashboard';
import RegisterChild from './components/Forms/RegisterChild';
import RegisterClass from './components/Forms/CreateClass';
import VerifyEmail from './components/Login/VerifyEmail';
import ResetPassword from './components/Login/PasswordReset';
import ChangePassword from './components/Login/ChangePassword';

const trackingId = 'G-RC4L7JQL05';
ReactGA.initialize(trackingId);

function KidsMainRedirect() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/kidsmain/kidsmainslide");
  }, [navigate]);

  return null;
}

function App() {
  useEffect(() => {
    ReactGA.send(window.location.pathname);
  }, []);

  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register/parent" element={<RegisterParent />} />
          <Route path="/register/teacher" element={<RegisterTeacher />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/reset/password/:token" element={<ResetPassword />} />
          <Route path="/reset/change-password" element={<ResetPassword />} />
          <Route path="/parent-dashboard" element={<ProtectedRoute userRole="parent"><ParentDashboard /></ProtectedRoute>} />
          <Route path="/teacher-dashboard" element={<ProtectedRoute userRole="teacher"><TeacherDashboard /></ProtectedRoute>} />
          <Route path="/child-dashboard" element={<ProtectedRoute userRole="child"><ChildDashboard /></ProtectedRoute>} />
          <Route path="/add-child" element={<ProtectedRoute userRole={["parent", "teacher"]}><RegisterChild /></ProtectedRoute>} />
          <Route path="/create-class" element={<ProtectedRoute userRole={["teacher"]}><RegisterClass /></ProtectedRoute>} />
          <Route path="/kidsmain" element={<KidsMain />}>
            <Route index element={<KidsRedirect />} />
            <Route path="kidsmainslide" element={<KidsmainSlide />} />
            <Route path="magazine" element={<KidsmainMagazine />} />
            <Route path="homelearning" element={<HomeLearning />} />
            <Route path="homelearning/:lessonId" element={<HLPreview />} />
            <Route path="teacherlessons" element={<TeacherLessons />} />
            <Route path="teacherlessons/:lessonId" element={<TLessonPreview />} />
            <Route path="bedtimestories" element={<KidsmainBedtimeStories />} />
            <Route path="create" element={<Create />} />
            <Route path="submissions" element={<Submissions />} />
            <Route path="friends" element={<Friends />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
