import React, { useEffect, useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars, faCaretDown, faCaretUp, faXmark, faUser
} from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import RegisterSign from "../Login/RegisterSign"; // Import HomePage as RegisterSign
import defaultImage from "../../images/UserProfile/Avatar_default.png";
import checkpointStacked from "../../images/checkpoint_logos/Checkpoint®_stacked_72ppi.png";

import "../../css/navbar.css";

function Navbar() {
  const { user, logout, loading, profilePic, setProfilePic } = useContext(AuthContext);
  const [activeSection, setActiveSection] = useState(null);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [mobileIcon, setMobileIcon] = useState(faBars);
  const [dropdownArrow, setDropdownArrow] = useState(faCaretDown);
  const [showRegisterOrSign, setShowRegisterOrSign] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setShowLogo(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = (section) => {
    setActiveSection(section);
    const element = document.getElementById(section);
    const offset = element.offsetTop;
    const navbarHeight = showMobileMenu ? 375 : 50;
    window.scrollTo({ top: offset - navbarHeight, behavior: "smooth" });
  };

  const toggleMobileMenu = () => {
    if (showMobileMenu && showRegisterOrSign) {
      setShowRegisterOrSign(false);
    }
    setShowMobileMenu(!showMobileMenu);
    if (!showMobileMenu) {
      setDropdownArrow(faCaretDown);
      setShowDropdown(false);
    }
    setMobileIcon(showMobileMenu ? faBars : faXmark);
  };

  const toggleDropdownMenu = () => {
    setShowDropdown(!showDropdown);
  };

  const handleDropdownArrowClick = () => {
    setDropdownArrow(dropdownArrow === faCaretDown ? faCaretUp : faCaretDown);
  };

  const toggleRegisterOrSign = () => {
    setShowRegisterOrSign(!showRegisterOrSign);
  };

  const handleRegisterSignClose = () => {
    setShowRegisterOrSign(false);
    window.location.reload();
  };

  const toggleProfileDropdown = () => {
    setShowProfileDropdown(!showProfileDropdown);
  };

  return (
    <div className="navbar-container">
      <div className="navbar-wrapper">
        <div className="logo-menu-container">
          {showLogo && (
            <div className="checkpointLogo">
              <img src={checkpointStacked} alt="logo" style={{ height: "60px" }} />
            </div>
          )}
          <div className="icon-menu" onClick={toggleMobileMenu}>
            <FontAwesomeIcon
              className={`dropdown-icon ${showMobileMenu ? "open" : ""}`}
              icon={mobileIcon}
              onClick={toggleMobileMenu}
            />
          </div>
        </div>
        <div className={`navbar-menu ${showMobileMenu ? "show" : ""}`}>
          {["Magazine", "Kids Magazine", "Learning", "Bedtime Stories", "Multiverse", "Shop", "Affiliations"].map((section) => (
            <button
              key={section}
              className="navbar-item"
              id={activeSection === section.toLowerCase().replace(" ", "") ? "active" : ""}
              onClick={() => scrollToSection(section.toLowerCase().replace(" ", ""))}
            >
              {section}
            </button>
          ))}
          <button className="navbar-item">
            <a href="/kidsmain/submissions">Kids Submissions</a>
          </button>
          <div className={`navbar-dropdown ${showDropdown ? "show" : ""}`} onClick={toggleDropdownMenu}>
            <button className="navbar-item" onClick={handleDropdownArrowClick}>
              Research
              <FontAwesomeIcon className="dropdown-arrow" icon={dropdownArrow} />
            </button>
            <div className="dropdown-content">
              {showDropdown && (
                <a href="https://checkpointmagazine.com/videogame-sector-esg-report-white-paper/">
                  ESG White Paper 2023
                </a>
              )}
            </div>
          </div>

          <div className="nav-item-profile-dropdown-container">
            {user ? (
              <div className={`profile-dropdown ${showProfileDropdown ? "show" : ""}`} onClick={toggleProfileDropdown}>
                <div className="profile-info">
                  <img src={profilePic} alt="Profile" className="user-icon" />
                  <span className="user-name">{user.name}</span>
                </div>
                {showProfileDropdown && (
                  <div className="profile-dropdown-content">
                    <a href="/dashboard">Dashboard</a>
                    <a onClick={logout}>Logout</a>
                  </div>
                )}
              </div>
            ) : (
              <button className="navbar-item" onClick={toggleRegisterOrSign}>
                <FontAwesomeIcon icon={faUser} className="login-signup-icon" />
                Login/Signup
              </button>
            )}
            {showRegisterOrSign && <RegisterSign onClose={handleRegisterSignClose} />}
          </div>
        </div>
      </div>

      {showMobileMenu && (
        <div className="mobile-dropdown-menu">
          <div className="nav-item-profile-dropdown-container2">
            {user ? (
              <div className={`profile-dropdown ${showProfileDropdown ? "show" : ""}`} onClick={toggleProfileDropdown}>
                <div className="profile-info">
                  <img src={profilePic} alt="Profile" className="user-icon" />
                  <span className="user-name">{user.name}</span>
                </div>
                {showProfileDropdown && (
                  <div className="profile-dropdown-content">
                    <a href="/dashboard">Dashboard</a>
                    <a onClick={logout}>Logout</a>
                  </div>
                )}
              </div>
            ) : (
              <button className="login-signup-btn" onClick={toggleRegisterOrSign}>
                <FontAwesomeIcon icon={faUser} className="login-signup-icon" />
                Login/Signup
              </button>
            )}
            {showRegisterOrSign && <RegisterSign onClose={handleRegisterSignClose} />}
          </div>
          {["Magazine", "Kids Magazine", "Learning", "Bedtime Stories", "Multiverse", "Shop", "Affiliations"].map((section) => (
            <button
              key={section}
              className="navbar-item"
              id={activeSection === section.toLowerCase().replace(" ", "") ? "active" : ""}
              onClick={() => {
                scrollToSection(section.toLowerCase().replace(" ", ""));
                toggleMobileMenu();
              }}
            >
              {section}
            </button>
          ))}
          <button className="navbar-item">
            <a href="/kidsmain/submissions">Kids Submissions</a>
          </button>
          <div className={`navbar-dropdown ${showDropdown ? "show" : ""}`} onClick={toggleDropdownMenu}>
            <button className="navbar-item" onClick={handleDropdownArrowClick}>
              Research
              <FontAwesomeIcon className="dropdown-arrow" icon={dropdownArrow} />
            </button>
            <div className="dropdown-content">
              {showDropdown && (
                <a href="https://checkpointmagazine.com/videogame-sector-esg-report-white-paper/">
                  ESG White Paper 2023
                </a>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Navbar;
