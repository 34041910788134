import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate, useParams } from 'react-router-dom';
import learnlogo from '../../images/checkpoint_logos/learninglogo.png';
import lessonData from '../../data/teacherlessons.json';
import '../../css/Kids/teacherlessons.css';
import { AnalyticsEvent } from '../Analytics';
import { FaBookmark } from 'react-icons/fa';
import axiosInstance, { addBookmark, removeBookmark } from '../../services/axiosInstance';
import '../../css/Forms/overlay.css';
import { setCache, getCache, clearCache } from '../../utils/cacheUtils';

const ErrorPopup = ({ message, onClose }) => {
    return (
        <div className="overlay">
            <div className="error-popup">
                <p>{message}</p>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default function TeacherLessons() {
    const [hoveredImg, setHoveredImg] = useState(null);
    const [lesson, setLesson] = useState({});
    const [showPopup, setShowPopup] = useState(false);
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [classes, setClasses] = useState([]);
    const [user, setUser] = useState(null);
    const [teacherId, setTeacherId] = useState(null);
    const [bookmarkedLessons, setBookmarkedLessons] = useState([]);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const { lessonId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const selectedLesson = lessonData.find((lesson) => lesson.id.toString() === lessonId);
        setLesson(selectedLesson);
    }, [lessonId]);

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: '/kidsmain/teacherlessons', title: 'Checkpoint Teacher Lessons' });
    }, []);

    useEffect(() => {
        const fetchUserAndClassesAndBookmarks = async () => {
            try {
                const token = localStorage.getItem('token');
                if (token) {
                    let userData = getCache('user');
                    let fetchedTeacherId = getCache('teacherId');
                    let fetchedClasses = getCache('classes');
                    let cachedBookmarks = getCache('bookmarkedLessons');

                    if (!userData) {
                        const userResponse = await axiosInstance.get('/user', {
                            headers: { Authorization: `Bearer ${token}` },
                        });
                        userData = userResponse.data.user;
                        setCache('user', userData);
                    }
                    setUser(userData);

                    if (userData.role === 'teacher') {
                        if (!fetchedTeacherId) {
                            const teacherIdResponse = await axiosInstance.get(`/teacher/${userData.id}/teacher-id`, {
                                headers: { Authorization: `Bearer ${token}` },
                            });
                            fetchedTeacherId = teacherIdResponse.data.teacher_id;
                            setCache('teacherId', fetchedTeacherId);
                        }
                        setTeacherId(fetchedTeacherId);

                        if (fetchedTeacherId) {
                            if (!fetchedClasses) {
                                const classesResponse = await axiosInstance.get(`/teacher/${fetchedTeacherId}/classes`, {
                                    headers: { Authorization: `Bearer ${token}` },
                                });
                                fetchedClasses = classesResponse.data;
                                setCache('classes', fetchedClasses);
                            }
                            setClasses(fetchedClasses);

                            if (!cachedBookmarks) {
                                const bookmarksPromises = fetchedClasses.map(async (classItem) => {
                                    const bookmarksResponse = await axiosInstance.get(`/bookmarks/${classItem.id}`, {
                                        headers: { Authorization: `Bearer ${token}` },
                                    });
                                    return bookmarksResponse.data.map((bookmark) => bookmark.resource_id.toString());
                                });

                                const allBookmarks = await Promise.all(bookmarksPromises);
                                cachedBookmarks = allBookmarks.flat();
                                setCache('bookmarkedLessons', cachedBookmarks);
                            }
                            setBookmarkedLessons(cachedBookmarks);
                        }
                    }
                }
            } catch (error) {
                console.error('Failed to fetch user, classes, or bookmarks:', error);
            }
        };

        if (!user) {
            const storedBookmarks = getCache('bookmarkedLessons');
            if (storedBookmarks) {
                setBookmarkedLessons(storedBookmarks);
            }
            fetchUserAndClassesAndBookmarks();
        }
    }, [user]);

    const handleMouseEnter = (index) => {
        setHoveredImg(index);
    };

    const handleMouseLeave = () => {
        setHoveredImg(null);
    };

    const handleNavigate = (lessonId) => {
        navigate(`/kidsmain/teacherlessons/${lessonId}`);
        AnalyticsEvent('Opened lesson', 'Clicked Teacher Lesson', 'lesson ' + (Number(lessonId) + 1));
    };

    const handleBookmarkClick = (event, lesson) => {
        event.stopPropagation();
        if (classes.length === 1) {
            handleAddBookmark(classes[0].id, lesson);
        } else {
            setSelectedLesson(lesson);
            setShowPopup(true);
        }
    };

    const handleRemoveBookmark = async (event, lessonId) => {
        event.stopPropagation();
        try {
            const token = localStorage.getItem('token');
            if (token && user) {
                await removeBookmark(lessonId);
                const updatedBookmarks = bookmarkedLessons.filter(id => id !== lessonId.toString());
                setBookmarkedLessons(updatedBookmarks);
                setCache('bookmarkedLessons', updatedBookmarks);
            }
        } catch (error) {
            console.error('Failed to remove bookmark:', error);
        }
    };

    const closePopup = () => {
        setShowPopup(false);
        setSelectedLesson(null);
    };

    const handleAddBookmark = async (classId, lesson = selectedLesson) => {
        try {
            const token = localStorage.getItem('token');
            if (token && user) {
                const response = await addBookmark({
                    teacher_id: teacherId,
                    class_id: classId,
                    resource_id: lesson.id,
                    subject: lesson.subject,
                    theme: lesson.theme,
                    outcome: lesson.outcome,
                    resource_link: `/kidsmain/teacherlessons/${lesson.id}`,
                    title_code: lesson.titlecode,
                });

                if (response.status === 201) {
                    closePopup();
                    const updatedBookmarks = [...bookmarkedLessons, lesson.id.toString()];
                    setBookmarkedLessons(updatedBookmarks);
                    setCache('bookmarkedLessons', updatedBookmarks);
                } else if (response.status === 409) {
                    alert('This resource is already bookmarked for this class.');
                }
            }
        } catch (error) {
            console.error('Failed to add bookmark:', error);
        }
    };

    const handleAddClass = async (newClass) => {
        try {
            setClasses((prevClasses) => [...prevClasses, newClass]);

            const token = localStorage.getItem('token');
            if (token) {
                const bookmarksResponse = await axiosInstance.get(`/bookmarks/${newClass.id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const newBookmarks = bookmarksResponse.data.map((bookmark) => bookmark.resource_id.toString());
                const updatedBookmarks = [...bookmarkedLessons, ...newBookmarks];
                setBookmarkedLessons(updatedBookmarks);
                setCache('bookmarkedLessons', updatedBookmarks);
            }
        } catch (error) {
            console.error('Failed to fetch bookmarks for new class:', error);
        }
    };

    return (
        <div>
            <div className="tl-header">
                <div className="tl-title">
                    <img src={learnlogo} alt="checkpoint learning logo" />
                    <h1>Teacher Lesson Plans</h1>
                </div>
                <div className="tl-gallery-main">
                    <div className="tl-dsp">
                        <p>
                            At Checkpoint Kids we’ve designed free lesson plans inspired by videogames for you to use in class.
                            Everything you need is included in the pack: detailed lesson plans, resource materials, video clip links,
                            worksheets, self-assessments etc.
                        </p>
                        <p>
                            Each pack contains up to three 45-minute lessons and there are opportunities to expand and develop them
                            in a variety of interesting ways. Although the lessons take their lead from games, there is no requirement
                            to play or to have played them.
                        </p>
                    </div>
                </div>
            </div>
            <div className="tl-gallery">
                {lessonData.map((item, index) => (
                    <div
                        key={index}
                        className={`tl-image-container ${hoveredImg === index ? 'tl-hovered' : ''}`}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => handleNavigate(index)}
                    >
                        {hoveredImg === index && (
                            <div className="tl-hover">
                                <p>Subject: {item.subject}</p>
                                <p>Theme: {item.theme}</p>
                                <p>Outcome: {item.outcome}</p>
                                {user?.role === 'teacher' && !bookmarkedLessons.includes(item.id.toString()) && (
                                    <FaBookmark className="bookmark-icon" onClick={(event) => handleBookmarkClick(event, item)} />
                                )}
                            </div>
                        )}
                        <img src={item.image} alt={`lesson ${index + 1} cover`} />
                        
                        {user?.role === 'teacher' && bookmarkedLessons.includes(item.id.toString()) && (
                            <FaBookmark className="bookmarked-icon" onClick={(event) => handleRemoveBookmark(event, item.id.toString())} />
                        )}
                    </div>
                ))}
            </div>
            {showPopup && (
                <BookmarkPopup lesson={selectedLesson} classes={classes} onClose={closePopup} onAddBookmark={handleAddBookmark} />
            )}
            {showErrorPopup && (
                <ErrorPopup message="Parents cannot bookmark teachers lesson plan." onClose={() => setShowErrorPopup(false)} />
            )}
        </div>
    );
}

function BookmarkPopup({ lesson, classes, onClose, onAddBookmark }) {
    const [classId, setClassId] = useState('');

    const handleClassChange = (e) => {
        const selectedClassId = e.target.value;
        setClassId(selectedClassId);
        if (selectedClassId) {
            onAddBookmark(selectedClassId);
        }
    };

    return (
        <div className="overlay">
            <div className="form_wrapper">
                <button className="close-btn" onClick={onClose}>
                    ×
                </button>
                <div className="form_container">
                    <div className="title_container">
                        <h3 className="lessonHeader">Which class do you want to add this to?</h3>
                        <p className="lessonTitle">{lesson.title}</p>
                    </div>
                    <select className="input_field" value={classId} onChange={handleClassChange}>
                        <option value="">Choose Class</option>
                        {classes.map((classItem) => (
                            <option key={classItem.id} value={classItem.id}>
                                {classItem.class_name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
}
