import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import ReactGA from "react-ga4";
import Header from "../components/Homepage/Header.jsx";
import Navbar from "../components/Homepage/Navbar.jsx";
import CheckpointMagazine from "../components/Homepage/CheckpointMagazine.jsx";
import KidsMagazine from "../components/Homepage/KidsMagazine.jsx";
import CheckpointLearning from "../components/Homepage/CheckpointLearning.jsx";
import BedtimeStories from "../components/Homepage/BedtimeStories.jsx";
import Multiverse from "../components/Homepage/Multiverse.jsx";
import Sponsors from "../components/Homepage/Sponsors.jsx";
import Footer from "../components/Homepage/Footer.jsx";
import Shop from "../components/Homepage/Shop.jsx";
import ResetPassword from "../components/Login/PasswordReset.jsx";

import "../css/home.css";

function Home() {
  const location = useLocation();
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/", title: "Checkpoint Hub Home" });

    const queryParams = new URLSearchParams(location.search);
    const resetToken = queryParams.get('resetToken');
    const email = queryParams.get('email');

    if (resetToken && email) {
      setToken(resetToken);
      setEmail(email);
      setShowResetPassword(true);
    }
  }, [location]);

  const handleClose = () => {
    setShowResetPassword(false);
    setToken('');
    setEmail('');
    window.history.replaceState({}, document.title, "/"); // Remove query params from URL
  };

  return (
    <div className="home-container">
      <div className="item1">
        <Header />
      </div>
      <div className="item2">
        <Navbar />
      </div>
      <div className="item3" id="magazine">
        <CheckpointMagazine />
      </div>
      <div className="item4" id="kidsmagazine">
        <KidsMagazine />
      </div>
      <div className="item5" id="learning">
        <CheckpointLearning />
      </div>
      <div className="item6" id="bedtimestories">
        <BedtimeStories />
      </div>
      <div className="item7" id="multiverse">
        <Multiverse />
      </div>
      <div className="item8" id="shop">
        <Shop />
      </div>
      <div className="item9" id="affiliations">
        <Sponsors />
      </div>
      <div className="item10">
        <Footer />
      </div>
      {showResetPassword && (
        <ResetPassword token={token} email={email} onClose={handleClose} />
      )}
    </div>
  );
}

export default Home;
