import React, { useState, useEffect } from 'react';
import { getTeacherId, getClasses } from '../../services/axiosInstance';
import '../../css/Forms/createClass.css';

const FilterClassOverlay = ({ user, onClose, onClassChange }) => {
    const [classes, setClasses] = useState([]);
    const [selectedClass, setSelectedClass] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchClasses = async () => {
            try {
                const teacherId = await getTeacherId(user.id);
                const response = await getClasses(teacherId);
                setClasses(response);
            } catch (error) {
                setError('Failed to fetch classes');
            }
        };

        fetchClasses();
    }, [user.id]);

    const handleClassChange = (e) => {
        const selectedClassId = e.target.value;
        setSelectedClass(selectedClassId);
        onClassChange(selectedClassId);
        onClose();
    };

    return (
        <div className="create-class-overlay">
            <div className="create-class-form-wrapper">
                <button className="create-class-close-btn" onClick={onClose}>×</button>
                <div className="create-class-form-container">
                    <div className="create-class-title-container">
                        <h2>Class Selection</h2>
                    </div>
                    {error && <p className="create-class-error-message">{error}</p>}
                    <div className="create-class-row create-class-clearfix">
                        <div className="create-class-input-field">
                            <select
                                value={selectedClass}
                                onChange={handleClassChange}
                                className="create-class-dropdown"
                            >
                                <option value="">Select Class</option>
                                {classes.map((cls) => (
                                    <option key={cls.id} value={cls.id}>
                                        {cls.class_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FilterClassOverlay;
