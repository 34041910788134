// HomePage.js (RegisterSign)
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import RegisterAdultOverlay from './RegisterAdultOverlay';
import RegisterTeacherOverlay from './RegisterTeacherOverlay'; 
import LoginOverlay from './LoginOverlay';
import image from "../../images/kidsmagazine_front.png";
import "../../css/Login/registerSign.css";

const HomePage = ({ onClose }) => { // Accept onClose prop
  const [isPopupVisible, setPopupVisible] = useState(true);
  const [selectedRole, setSelectedRole] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const navigate = useNavigate();

  const navigateToRegister = (role) => {
    setSelectedRole(role);
    setIsRegisterOpen(true);
    setPopupVisible(false);
  };

  const navigateToLogin = () => {
    setIsRegisterOpen(false);
    setIsLoginOpen(true);
    setPopupVisible(false);
  };

  const closeOverlay = () => {
    setIsRegisterOpen(false);
    setIsLoginOpen(false);
    setPopupVisible(false);
    if (onClose) onClose(); // Call onClose prop
  };

  return (
    <div>
      <div className={`overlay ${isPopupVisible || isRegisterOpen || isLoginOpen ? '' : 'hidden'}`}>
        {isPopupVisible && (
          <div className="popup register-sign-container">
            <button className="close-btn" onClick={closeOverlay}>×</button>
            <h4>Would you like to have access to more content?</h4>
            <div className="register-sign-buttons">
              <button className="registration-button" onClick={() => navigateToRegister('Adult')}>Parent sign up</button>
              <button className="registration-button" onClick={() => navigateToRegister('Teacher')}>Teacher sign up</button>
            </div>
            <div className="sign-in-link">
              <span>Already have an account? </span>
              <a href="#" onClick={navigateToLogin}><span className="underline">Click here to sign in</span></a>
            </div>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
          </div>
        )}
      </div>
      {isRegisterOpen && selectedRole === "Adult" && <RegisterAdultOverlay onClose={closeOverlay} />}
      {isRegisterOpen && selectedRole === "Teacher" && <RegisterTeacherOverlay onClose={closeOverlay} />}
      {isLoginOpen && <LoginOverlay onClose={closeOverlay} />}
    </div>
  );
};

export default HomePage;
