import React from 'react';
import "../../css/Forms/overlay.css";

const VerifyEmail = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="form_wrapper">
      <div className="form_container">
        <button className="close-btn" onClick={onClose}>×</button>
        <h1>Verify Your Email</h1>
        <p>Please check your email to verify your account</p>
        
      </div>
    </div>
  );
};

export default VerifyEmail;
