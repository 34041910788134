import React, { useState, useEffect, useContext } from 'react';
import { getChildData, getProfilePic, setProfilePic } from '../../services/axiosInstance';
import AvatarSelectionOverlay from './AvatarSelection';
import KidsNavbar from '../Kids/KidsNavbar';
import defaultImage from "../../images/UserProfile/Avatar_default.png";
import addGraphic from "../../images/Addgraphic.png";
import Footer from "../../components/Homepage/Footer.jsx";
import "../../css/Dashboards/ParentDashboard.css";
import { AuthContext } from '../../contexts/AuthContext';

const ChildDashboard = () => {
    const { user, profilePic, setProfilePic } = useContext(AuthContext);
    const [child, setChild] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [isAvatarSelectionOpen, setIsAvatarSelectionOpen] = useState(false);

    useEffect(() => {
        const fetchChildData = async () => {
            try {
                const response = await getChildData(user.id);
                setChild(response);
                const profilePicResponse = await getProfilePic();
                setProfilePic(profilePicResponse || defaultImage);
                setLoading(false);
            } catch (error) {
                setError('Failed to fetch child data.');
                setLoading(false);
            }
        };
        fetchChildData();
    }, [user.id, setProfilePic]);

    const updateProfilePic = async (newProfilePic) => {
        try {
            await setProfilePic(newProfilePic);
            setProfilePic(newProfilePic);
            closeAvatarSelection();
        } catch (error) {
            console.error('Failed to update profile picture', error);
        }
    };

    const openAvatarSelection = () => {
        setIsAvatarSelectionOpen(true);
    };

    const closeAvatarSelection = () => {
        setIsAvatarSelectionOpen(false);
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status"></div>
            </div>
        );
    }

    return (
        <div className="parent-dashboard-home-container">
            <div className="dasboard">
                <KidsNavbar />
            </div>
            <div className="parent-dashboard-content">
                <div className="parent-content">
                    <h1 className="parent-title">Child Account</h1>
                    <div className="parent-profile-container">
                        <div className="profile-container">
                            <img src={profilePic} alt="Profile" className="profile-image" />
                            <div className="profile-overlay" onClick={openAvatarSelection}>
                                <img src={addGraphic} alt="Change Profile" />
                            </div>
                        </div>
                        <div className="parent-info">
                            <p className="parent-username">Username: {child?.name}</p>
                        </div>
                    </div>
                    {isAvatarSelectionOpen && <AvatarSelectionOverlay onClose={closeAvatarSelection} onSelectAvatar={updateProfilePic} />}
                    {error && <p className="error-message">{error}</p>}
                </div>
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>
    );
};

export default ChildDashboard;
