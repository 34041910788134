import React, { useState, useEffect } from 'react';
import { getAvatar, setProfilePic } from '../../services/axiosInstance';
import '../../css/Forms/avatarSelection.css';

const AvatarSelectionOverlay = ({ onClose, onSelectAvatar }) => {
    const [avatars, setAvatars] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAvatars = async () => {
            try {
                const data = await getAvatar();
                setAvatars(data);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch avatars', error);
                setError('Failed to fetch avatars');
                setLoading(false);
            }
        };

        fetchAvatars();
    }, []);

    const handleAvatarClick = async (avatar) => {
        try {
            await setProfilePic(avatar);
            onSelectAvatar(avatar);
            onClose();
        } catch (error) {
            console.error('Failed to set avatar:', error);
        }
    };

    return (
        <div className="overlay">
            <div className="avatar-selection-wrapper">
                <button className="close-btn" onClick={onClose}>×</button>
                <h2 className="title">Select an Avatar</h2>
                <div className="avatars-container">
                    {avatars.map((avatar, index) => (
                        <div key={index} className="avatar-item" onClick={() => handleAvatarClick(avatar)}>
                            <img src={avatar} alt={`Avatar ${index + 1}`} className="avatar-img" />
                        </div>
                    ))}
                </div>
                {loading && <p>Loading avatars...</p>}
                {error && <p>{error}</p>}
            </div>
        </div>
    );
};

export default AvatarSelectionOverlay;
