import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { registerTeacher } from '../../services/axiosInstance';
import AsyncSelect from 'react-select/async';
import "../../css/Forms/overlay.css";
import CustomPopup from '../../popup/popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const API_URL = 'http://127.0.0.1:8000/api';

const customSelectStyles = {
    control: (provided, state) => ({
        ...provided,
        borderRadius: '8px',
        borderColor: state.isFocused ? 'var(--input-focus)' : 'var(--input-border)',
        boxShadow: state.isFocused ? '0 0 1px var(--input-focus)' : 'none',
        transition: 'border-color 0.3s, box-shadow 0.5s',
        '&:hover': {
            borderColor: state.isFocused ? 'var(--input-focus)' : 'var(--input-hover-border)'
        }
    }),
    menu: (provided) => ({
        ...provided,
        color: '#000', 
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'var(--input-focus)' : '#fff',
        color: state.isSelected ? '#fff' : '#000',
        '&:hover': {
            backgroundColor: 'var(--input-focus)',
            color: '#fff'
        }
    })
};

const RegisterTeacherOverlay = ({ onClose, onRegisterSuccess }) => {
    const MAX_USERNAME_LENGTH = 20;
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        firstname: '',
        lastname: '',
        schoolId: ''
    });
    const [error, setError] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [success, setSuccess] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const navigate = useNavigate();

    const fetchSchoolOptions = async (inputValue) => {
        try {
            const response = await axios.get(`${API_URL}/schools`, {
                params: { search: inputValue }
            });
            return response.data.map(school => ({
                value: school.id,
                label: school.school_name
            }));
        } catch (error) {
            console.error('Error fetching schools', error);
            return [];
        }
    };

    const handlePopupClose = () => {
        setShowPopup(false);
        navigate('/login');
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'name' && value.length > MAX_USERNAME_LENGTH) {
            setUsernameError(`Username cannot exceed ${MAX_USERNAME_LENGTH} characters`);
        } else {
            setUsernameError('');
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, email, password, confirmPassword, firstname, lastname, schoolId } = formData;
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }
        try {
            const response = await registerTeacher(
                name,
                email,
                password,
                confirmPassword,
                firstname,
                lastname,
                schoolId
            );
            console.log('Registration successful', response);

            setSuccess(true);
            setShowPopup(true);

            setFormData({
                name: '',
                email: '',
                password: '',
                confirmPassword: '',
                firstname: '',
                lastname: '',
                schoolId: ''
            });
        } catch (error) {
            console.error('Registration failed', error);
            if (error.response?.status === 404) {
                setError("Email domain doesn't match school domain");
            } else {
                setError(error.response?.data?.message || 'Registration failed. Please try again.');
            }
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <div className="overlay">
            <div className="form_wrapper">
                {showPopup && (
                    <CustomPopup onClose={handlePopupClose}>
                        <h2 style={{ color: 'white', textAlign: 'center' }}>Registration successful</h2>
                        <p style={{ color: 'white', textAlign: 'center' }}>A verification email has been sent to your email address</p>
                    </CustomPopup>
                )}
                <button className="close-btn" onClick={onClose}>×</button>
                <div className="form_container">
                    <div className="title_container">
                        <h2>Teacher Registration</h2>
                    </div>
                    {error && <p className="error-message">{error}</p>}
                    {success && <p className="success-message">{success}</p>}
                    <div className="row clearfix">
                        <div className="">
                            <form onSubmit={handleSubmit}>
                                <div className="input_field">
                                    <span><i aria-hidden="true" className="fa fa-user"></i></span>
                                    <input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        placeholder="Username"
                                        required
                                    />
                                    {usernameError && <p className="error-message">{usernameError}</p>}
                                </div>
                                <div className="input_field">
                                    <span><i aria-hidden="true" className="fa fa-envelope"></i></span>
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        placeholder="Email"
                                        required
                                    />
                                </div>
                                <div className="input_field">
                                    <span><i aria-hidden="true" className="fa fa-lock"></i></span>
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        placeholder="Password"
                                        required
                                    />
                                    <button type="button" className="toggle-password" onClick={togglePasswordVisibility}>
                                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                    </button>
                                </div>
                                <div className="input_field">
                                    <span><i aria-hidden="true" className="fa fa-lock"></i></span>
                                    <input
                                        type={showConfirmPassword ? "text" : "password"}
                                        name="confirmPassword"
                                        value={formData.confirmPassword}
                                        onChange={handleChange}
                                        placeholder="Confirm Password"
                                        required
                                    />
                                    <button type="button" className="toggle-password" onClick={toggleConfirmPasswordVisibility}>
                                        <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                                    </button>
                                </div>
                                <div className="row clearfix">
                                    <div className="col_half">
                                        <div className="input_field">
                                            <span><i aria-hidden="true" className="fa fa-user"></i></span>
                                            <input
                                                type="text"
                                                name="firstname"
                                                value={formData.firstname}
                                                onChange={handleChange}
                                                placeholder="First Name"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col_half">
                                        <div className="input_field">
                                            <span><i aria-hidden="true" className="fa fa-user"></i></span>
                                            <input
                                                type="text"
                                                name="lastname"
                                                value={formData.lastname}
                                                onChange={handleChange}
                                                placeholder="Last Name"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="input_field">
                                    <label>School:</label>
                                    <AsyncSelect
                                        styles={customSelectStyles}
                                        loadOptions={fetchSchoolOptions}
                                        onChange={(option) => setFormData(prevState => ({
                                            ...prevState,
                                            schoolId: option.value
                                        }))}
                                        placeholder="Select a School"
                                        className="async-select"
                                        cacheOptions
                                    />
                                </div>
                                <div className="flex-justify-end">
                                    <input className="button" type="submit" value="Register" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterTeacherOverlay;
