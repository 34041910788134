import React from 'react';
import "../../css/Forms/overlay.css";

const ChildDetailsOverlay = ({ child, onClose }) => {
    if (!child) return null;

    return (
        <div className="overlay">
            <div className="form_wrapper">
                <button className="close-btn" onClick={onClose}>×</button>
                <div className="form_container">
                    <div className="title_container">
                        <h2>Child Details</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="inputs">
                            <div className="input_field">
                                <label>Username:</label>
                                <p>{child.firstname}</p>
                            </div>
                            <div className="input_field">
                                <label>Email:</label>
                                <p>{child.email}</p>
                            </div>
                            <div className="input_field">
                                <label>Profile Picture:</label>
                                <img src={child.profile_pic} alt="Child Avatar" className="child-avatar" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChildDetailsOverlay;
