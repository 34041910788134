import React, { useState, useEffect, useContext } from 'react';
import { getTeacherData, getTeacherClasses, getProfilePic, setProfilePic, removeBookmark, getBookmarksByClass, deleteClass } from '../../services/axiosInstance';
import AvatarSelectionOverlay from './AvatarSelection';
import ChangePasswordOverlay from '../Login/ChangePassword';
import defaultImage from "../../images/UserProfile/Avatar_default.png";
import addGraphic from "../../images/Addgraphic.png";
import KidsNavbar from "../../components/Kids/KidsNavbar.jsx";
import Footer from "../../components/Homepage/Footer.jsx";
import CreateClassOverlay from '../Forms/CreateClass.jsx';
import FilterClassOverlay from '../Forms/FilterClass.jsx';
import { FaBookmark, FaRegTrashAlt } from "react-icons/fa";
import { AuthContext } from '../../contexts/AuthContext';
import { setCache, getCache, clearCache } from '../../utils/cacheUtils';
import "../../css/footer.css";
import "../../css/Kids/kidsnavbar.css";
import "../../css/Dashboards/TeacherDashboard.css";
import ConfirmationPopup from '../Forms/ConfirmationPopup'; 



const TeacherDashboard = () => {
    const { user, profilePic, setProfilePic } = useContext(AuthContext);
    const [teacher, setTeacher] = useState(null);
    const [localProfilePic, setLocalProfilePic] = useState(profilePic || defaultImage);
    const [isCreateClassOpen, setIsCreateClassOpen] = useState(false);
    const [isSelectClassOpen, setIsSelectClassOpen] = useState(false);
    const [isAvatarSelectionOpen, setIsAvatarSelectionOpen] = useState(false);
    const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
    const [classId, setClassId] = useState('');
    const [classes, setClasses] = useState([]);
    const [bookmarks, setBookmarks] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedClassName, setSelectedClassName] = useState('');
    const [showDeleteBookmarkPopup, setShowDeleteBookmarkPopup] = useState(false);
    const [bookmarkToDelete, setBookmarkToDelete] = useState(null);
    const [showDeleteClassPopup, setShowDeleteClassPopup] = useState(false);
    const [classToDelete, setClassToDelete] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let teacherData = getCache(`teacherData_${user.id}`);
                let classData = getCache(`classData_${user.id}`);
                let profilePicData = getCache(`profilePic_${user.id}`);

                if (!teacherData) {
                    const teacherResponse = await getTeacherData(user.id);
                    teacherData = teacherResponse.teacher;
                    setCache(`teacherData_${user.id}`, teacherData);
                }
                setTeacher(teacherData);
                console.log('Teacher data fetched:', teacherData);

                if (!classData) {
                    const classesResponse = await getTeacherClasses(teacherData.id);
                    classData = classesResponse;
                    setCache(`classData_${user.id}`, classData);
                }
                setClasses(classData);
                console.log('Classes data fetched:', classData);

                if (!profilePicData) {
                    const profilePicResponse = await getProfilePic();
                    profilePicData = profilePicResponse || defaultImage;
                    setCache(`profilePic_${user.id}`, profilePicData);
                }
                setLocalProfilePic(profilePicData);
                console.log('Profile picture fetched:', profilePicData);

                if (classData.length === 1) {
                    const singleClass = classData[0];
                    setClassId(singleClass.id);
                    setSelectedClassName(singleClass.class_name);
                    const bookmarksData = await getBookmarksByClass(singleClass.id);
                    setBookmarks(bookmarksData.data);
                    console.log('Bookmarks data fetched:', bookmarksData);
                }

            } catch (error) {
                console.error('Failed to fetch data', error);
                setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [user.id]);

    const handleClassChange = async (selectedClassId) => {
        setClassId(selectedClassId);
        const selectedClass = classes.find(c => c.id === parseInt(selectedClassId));
        console.log('Selected class ID:', selectedClassId);
        console.log('Matching class:', selectedClass);
        setSelectedClassName(selectedClass ? selectedClass.class_name : '');
        if (selectedClassId) {
            try {
                const bookmarksData = await getBookmarksByClass(selectedClassId);
                setBookmarks(bookmarksData.data);
                console.log('Bookmarks data fetched:', bookmarksData);
            } catch (error) {
                console.error('Failed to fetch bookmarks', error);
            }
        }
    };

    const handleClassAdded = (newClass) => {
        setClasses((prevClasses) => [...prevClasses, newClass]);
        clearCache(`classData_${user.id}`);
    };

    const handleRemoveBookmark = (bookmarkId) => {
        setBookmarkToDelete(bookmarkId);
        setShowDeleteBookmarkPopup(true);
    };

    const confirmDeleteBookmark = async () => {
        try {
            await removeBookmark(bookmarkToDelete, teacher.id, classId);
            const updatedBookmarks = bookmarks.filter(bm => bm.id !== bookmarkToDelete);
            setBookmarks(updatedBookmarks);
            setCache('bookmarkedLessons', updatedBookmarks);
            setShowDeleteBookmarkPopup(false);
        } catch (error) {
            console.error('Failed to remove bookmark', error);
        }
    };

    const handleDeleteClass = (classId) => {
        setClassToDelete(classId);
        setShowDeleteClassPopup(true);
    };

    const confirmDeleteClass = async () => {
        try {
            await deleteClass(classToDelete);
            const updatedClasses = classes.filter(cls => cls.id !== classToDelete);
            setClasses(updatedClasses);
            setCache(`classData_${user.id}`, updatedClasses);
            setShowDeleteClassPopup(false);
            window.location.reload();
        } catch (error) {
            console.error('Failed to delete class', error);
        }
    };


    const updateProfilePic = async (newProfilePic) => {
        try {
            await setProfilePic(newProfilePic);
            setLocalProfilePic(newProfilePic);
            clearCache(`profilePic_${user.id}`);
            setCache(`profilePic_${user.id}`, newProfilePic);
            closeAvatarSelection();
        } catch (error) {
            console.error('Failed to update profile picture', error);
        }
    };

    const openAvatarSelection = () => {
        setIsAvatarSelectionOpen(true);
    };

    const closeAvatarSelection = () => {
        setIsAvatarSelectionOpen(false);
    };

    const openChangePassword = () => {
        setIsChangePasswordOpen(true);
    };

    const closeChangePassword = () => {
        setIsChangePasswordOpen(false);
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status"></div>
            </div>
        );
    }

    const navigateToCreateClass = () => {
        setIsCreateClassOpen(true);
    };

    const navigateToSelectClass = () => {
        setIsSelectClassOpen(true);
    };

    const closeOverlay = () => {
        setIsCreateClassOpen(false);
    };

    const closeSelectClassOverlay = () => {
        setIsSelectClassOpen(false);
    };

    if (error) {
        return <div>{error}</div>;
    }

    return (
        
        <div className="teacher-dashboard-home-container">
            <div className="dashboard">
                <KidsNavbar />
            </div>

            <div className="teacher-dashboard-content">

                <div className="teacher-content">
                    <h1 className="teacher-title">Teacher Account</h1>
                    <div className="teacher-profile-container">
                        <div className="profile-container">
                            <img src={localProfilePic} alt="Profile" className="profile-image" />
                            <div className="profile-overlay" onClick={openAvatarSelection}>
                                <img src={addGraphic} alt="Change Profile" />
                            </div>
                        </div>
                        <div className="teacher-info">
                            <p className="teacher-username">Username: {user.name}</p>
                            <p className="teacher-email">Email: {user.email}</p>
                            <button onClick={openChangePassword} className="teacher-change-password">Change password</button>
                        </div>
                    </div>
                    <div className="button-container">
                        <button className="teacher-add-class-btn" onClick={navigateToCreateClass}>Add Class</button>
                        <button className="teacher-select-class-btn" onClick={navigateToSelectClass}>Select Class</button>
                    </div>
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                </div>
                {isCreateClassOpen && <CreateClassOverlay user={user} onClose={closeOverlay} onClassAdded={handleClassAdded} />}
                {isSelectClassOpen && <FilterClassOverlay user={user} onClose={closeSelectClassOverlay} onClassChange={handleClassChange} />}
                {isAvatarSelectionOpen && <AvatarSelectionOverlay onClose={closeAvatarSelection} onSelectAvatar={updateProfilePic} />}
                {isChangePasswordOpen && <ChangePasswordOverlay onClose={closeChangePassword} />}
                <div className="bookmarks-container">
                    {bookmarks.length > 0 ? (
                        <ul>
                            <h3>Saved resources for {selectedClassName}</h3>
                            {bookmarks.map((bookmark) => (
                                <li key={bookmark.id}>
                                    <FaBookmark 
                                      className="bookmarked-icon-dashboard" 
                                      onClick={() => handleRemoveBookmark(bookmark.id)}
                                    />
                                    <div className="bookmark-content">
                                        <p className="bookmark-title">{bookmark.outcome} <span className="bookmark-code">{bookmark.title_code}</span></p>
                                        <p className="bookmark-details">{bookmark.subject} <a href={bookmark.resource_link} className="bookmark-link">Resources</a></p>
                                    </div>
                                </li>
                            ))}

                    <div className="delete-class-container">
                    <FaRegTrashAlt
                        className="delete-class-icon"
                        onClick={() => handleDeleteClass(classId)}
                    />
                    </div>      
                        </ul>
                    ) : (
                        <p className="noBookmarks">No Lesson plans found</p>
                    )}

                </div>
            </div>
            <div className="footer">
                <Footer />
            </div>
            {showDeleteBookmarkPopup && (
                <ConfirmationPopup
                    title="Are you sure you want to delete this bookmark?"
                    message=""
                    onConfirm={confirmDeleteBookmark}
                    onCancel={() => setShowDeleteBookmarkPopup(false)}
                />
            )}
            {showDeleteClassPopup && (
                <ConfirmationPopup
                    title="Are you sure you want to delete this class?"
                    message="This cannot be recovered after deleting."
                    onConfirm={confirmDeleteClass}
                    onCancel={() => setShowDeleteClassPopup(false)}
                />
            )}
        </div>
    );
};

export default TeacherDashboard;
