import React, { createContext, useState, useEffect } from 'react';
import { axiosNoPrefixInstance, getUser, getProfilePic, getParents } from '../services/axiosInstance';
import { useNavigate } from 'react-router-dom';
import defaultImage from "../images/UserProfile/Avatar_default.png";
import { setCache, getCache, clearCache } from '../utils/cacheUtils';


export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [profilePic, setProfilePic] = useState(defaultImage);
  const [loading, setLoading] = useState(true);
  const [childrenState, setChildrenState] = useState([]);
  const [childAvatars, setChildAvatars] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const cachedUser = getCache('userData');
          const cachedProfilePic = getCache(`profilePic_${cachedUser?.id}`);
          const cachedChildrenState = getCache('childrenState');
          const cachedChildAvatars = getCache('childAvatars');

          if (cachedUser) {
            setUser(cachedUser);
            setProfilePic(cachedProfilePic || defaultImage);
            setChildrenState(cachedChildrenState || []);
            setChildAvatars(cachedChildAvatars || {});
            setLoading(false);
          } else {
            const response = await getUser(token);
            setUser(response.data.user);
            setCache('userData', response.data.user);

            if (!response.data.user.email_verified_at) {
              navigate('/verify-email');
            } else {
              const profilePicResponse = await getProfilePic();
              setProfilePic(profilePicResponse || defaultImage);
              setCache(`profilePic_${response.data.user.id}`, profilePicResponse || defaultImage);

              if (response.data.user.role === 'parent') {
                const parentData = await getParents(response.data.user.id);
                setChildrenState(parentData.parent.children || []);
                setCache('childrenState', parentData.parent.children || []);

                const avatars = {};
                parentData.parent.children.forEach(child => {
                  avatars[child.id] = child.profile_pic;
                });
                setChildAvatars(avatars);
                setCache('childAvatars', avatars);
              }
            }
          }
        }
      } catch (error) {
        console.error('Failed to fetch user:', error);
        navigate('/');
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [navigate]);

  const deleteCsrfCookie = () => {
    document.cookie = "XSRF-TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  };

  const logout = async () => {
    try {
      await axiosNoPrefixInstance.post('/logout', null, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
    } catch (error) {
      console.error('Logout failed', error);
    } finally {
      localStorage.clear();
      deleteCsrfCookie();
      setUser(null);
      setProfilePic(defaultImage);
      setChildrenState([]);
      setChildAvatars({});
      navigate('/');
    }
  };

  const updateProfilePic = async (newProfilePic) => {
    try {
      // Immediately update state and cache
      setProfilePic(newProfilePic);
      setCache(`profilePic_${user.id}`, newProfilePic);

      // API call to update the profile picture
      await axiosNoPrefixInstance.post('/user/update-profile-pic', { profilePic: newProfilePic }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
    } catch (error) {
      console.error('Failed to update profile picture', error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, setUser, profilePic, setProfilePic, updateProfilePic, loading, logout, children: childrenState, setChildren: setChildrenState, childAvatars, setChildAvatars }}>
      {loading ? <div>Loading...</div> : children}
    </AuthContext.Provider>
  );
};
