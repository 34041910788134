import React from 'react';
import "../../css/Forms/createClass.css";

const ConfirmationPopup = ({ title, message, onConfirm, onCancel }) => {
    return (
        <div className="confirmation-popup-overlay">
            <div className="confirmation-popup-wrapper">
                <button className="confirmation-popup-close-btn" onClick={onCancel}>×</button>
                <div className="confirmation-popup-container">
                    <div className="confirmation-popup-title-container">
                        <h2>{title}</h2>
                    </div>
                    <p className="confirmation-popup-message">{message}</p>
                    <div className="confirmation-popup-buttons">
                        <button className="confirmation-popup-button" onClick={onConfirm}>Continue</button>
                        <button className="confirmation-popup-button" onClick={onCancel}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationPopup;
